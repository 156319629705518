/**
 * Оборачивает подстроку в тэг b
 */
export const wrapStringInBoldHtml = (
  str: string,
  searchQuery: string,
): string => {
  const regex = new RegExp(searchQuery, 'ig');
  return str.replace(regex, (source: string) => `<b>${source}</b>`);
};
