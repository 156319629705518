import { TShortProductResponse } from 'modules/search/types';
import CategoryList from '../category-list/category-list.component';

type TTradeOfferListProps = {
  popularProducts?: Array<TShortProductResponse>;
  newProducts?: Array<TShortProductResponse>;
};

export const TradeOfferList = ({
  popularProducts,
  newProducts,
}: TTradeOfferListProps): JSX.Element => {
  return (
    <>
      {popularProducts && (
        <CategoryList name="Популярные запросы" list={popularProducts} />
      )}
      {newProducts && <CategoryList name="Новинки" list={newProducts} />}
    </>
  );
};
