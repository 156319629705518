'use client';
import { useEffect } from 'react';

/**
 * Хук для подписки на изменение location
 * Варинт с useRouteChange не подходит
 * так как колбек не будет вызван повторно при historyReplace
 * @param handler
 */
export const useHistoryChange = (handler: (url: string) => void): void => {
  useEffect(() => {
    const originalPushState = createHistoryPatch('pushState', handler);
    const originalReplaceState = createHistoryPatch('replaceState', handler);
    return () => {
      history.pushState = originalPushState;
      history.replaceState = originalReplaceState;
    };
  }, []);
};

function createHistoryPatch(
  method: 'pushState' | 'replaceState',
  callback: (url: string) => void,
): (typeof history)['pushState'] | (typeof history)['replaceState'] {
  const fn = history[method];
  history[method] = function (
    ...args: [
      data: unknown,
      unused: string,
      url?: string | URL | null | undefined,
    ]
  ) {
    fn.apply(history, args);
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    callback(args[2] as string);
  };
  return fn;
}
