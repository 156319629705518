import { TShortProductResponse } from 'modules/search/types';
import Link from 'next/link';
import styles from '../../search.module.scss';

type TCategoryListProps = {
  /**
   * Название категории
   */
  name: string;

  /**
   * Список товаров
   */
  list: Array<TShortProductResponse>;
};

const CategoryList = ({ name, list }: TCategoryListProps): JSX.Element => (
  <div>
    <dt className={styles.categoryTitle}>{name}</dt>
    {list.map((product: TShortProductResponse) => (
      <dd key={product.slug} className={styles.listItem}>
        <Link className={styles.link} href={`${product.slug ?? ''}`}>
          {product.name}
        </Link>
      </dd>
    ))}
  </div>
);

export default CategoryList;
