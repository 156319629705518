'use client';
import { useScrollLock } from '@brand/utils';
import XIcon from '@tochka-modules/t15-ui-kit-icons-ssr/24/Stroked/Cross';
import cn from 'classnames';
import ReactModal from 'react-modal';
import styles from './modal.module.scss';
import { useRibbonContext } from '../../ribbon';

ReactModal.setAppElement('body');

type TModalProps = {
  closeClassName?: string;
} & ReactModal.Props;

/**
 * Данный компонент является оберткой для создания универсального модального окна на основе библиотеки react-modal.
 * Полный список пропсов доступен по ссылке https://reactcommunity.org/react-modal/
 */
const Modal = ({
  children,
  onRequestClose,
  closeClassName,
  ...props
}: TModalProps): JSX.Element => {
  const { isVisibleRibbon } = useRibbonContext();
  useScrollLock(props.isOpen);

  return (
    <ReactModal
      {...props}
      onRequestClose={onRequestClose}
      overlayClassName={styles.overlay}
      className={cn(
        styles.content,
        {
          [styles['content--ribbon']]: isVisibleRibbon,
        },
        props.className,
      )}
    >
      <>
        <button
          className={cn(closeClassName, styles.closeButton)}
          onClick={onRequestClose}
        >
          <XIcon />
        </button>
        {children}
      </>
    </ReactModal>
  );
};

export default Modal;
