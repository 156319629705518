export const POPULAR_PRODUCTS = [
  {
    name: 'Розовые очки Точка х Fakoshima',
    slug: '/product/rozovye-ochki-tochka-h-fakoshima-zolotaya-oprava/',
  },
  {
    name: 'Говорящая кружка',
    slug: '/product/govoryashchaya-kruzhka/',
  },
  {
    name: 'Плед «Место силы»',
    slug: '/product/pled-mesto-sily/',
  },
  {
    name: 'Матросская кепка «Не жду спокойной жизни»',
    slug: '/product/matrosskaya-kepka-ne-zhdu-spokojnoj-zhizni/',
  },
  {
    name: 'Жилет «Большая Удина»',
    slug: '/product/zhilet-bolshaya-udina/',
  },
  {
    name: 'Худи «Луна»',
    slug: '/product/hudi-luna/',
  },
];
