import { Typo, Button } from 'components/ui-kit';
import styles from '../../cart.module.scss';

export const headerCartEmptyTestSelectors = {
  headerCartEmpty: 'header-cart-empty',
};

export const HeaderCartEmptyBody = (): JSX.Element => {
  return (
    <div data-at={headerCartEmptyTestSelectors.headerCartEmpty}>
      <div className={styles.paddingCart}>
        <Typo align="center" variant="l">
          Ваша корзина пуста.
        </Typo>
      </div>
      <div className={styles.paddingCart}>
        <Button
          type="inner-link"
          variant="primary"
          size="xl"
          href="/new"
          isBlock
        >
          За покупками
        </Button>
      </div>
    </div>
  );
};
