export const concatSlugs = (...slugs: Array<string | null>): string =>
  `/${slugs.filter(Boolean).join('/')}`;

export const concatSlugsOrAbsolutePaths = (
  ...slugs: Array<string | null>
): string => {
  const target = concatSlugs(...slugs);
  return target.match(/(http|https):/g)
    ? slugs.filter(Boolean).join('/')
    : target;
};
