import { chunkArray } from '@brand/utils';
import cn from 'classnames';
import { ProductPrice } from 'components/product-price';
import { Row, Column, Spacer, Typo } from 'components/ui-kit';
import { MoneyFormatter, OfferPrice, PluralizeFormatter } from 'lib';
import { Currency } from 'lib/formatters/money.formatter';
import { COUNT } from 'lib/formatters/pluralize.formatter';
import Link from 'next/link';
import React from 'react';
import styles from './cart-position-item.module.scss';
import { CartPositions } from '../../../../api/models/cart-position.model';

type TProperty = {
  code: string;
  description: string;
  name: string;
  value: string;
};

type TCartPositionItemProps = {
  position: CartPositions;
  className?: string;
};

// TODO: добавить фото-заглушку, если у товара не пришло изображение
const DUMMY_IMAGE =
  'https://ishop-backend.query.consul-test/storage/app/uploads/public/61a/f1c/b48/61af1cb48c34e173467098.jpeg';

const NOT_SHOWING_PROPERTY_TYPES = ['color_code'];

export const cartItemHeaderCartTestSelectors = {
  headerCartItem: 'header-cart-item',
};
export function CartPositionItem({
  position,
  className,
}: TCartPositionItemProps): JSX.Element {
  const slug = position.product.slug;
  const img = position.product.previewImage?.src;
  const price = position.totalValue;
  const quantity = position.quantity;
  const quantityOffer = position.offer.quantity;
  const title = position.product.name;
  const properties = position.offer.property;

  const isItemsEnough = quantity <= quantityOffer;
  const remainWordPluralize = PluralizeFormatter.format(quantityOffer, COUNT);

  return (
    <Link href={`/product/${slug}`} className="clear-link">
      <div
        className={cn(className, styles.cartItem)}
        data-at={cartItemHeaderCartTestSelectors.headerCartItem}
      >
        <div className={styles.cartItemImgWrapper}>
          <img alt="" src={img ?? DUMMY_IMAGE} className={styles.cartItemImg} />
        </div>
        <div className={styles.cartItemInfo}>
          <Typo weight="500" variant="m">
            {title}
          </Typo>
          <Spacer size={8} />
          <ProductPrice offer={position.offer}>
            {({ priceValue, oldPriceValue }: OfferPrice) => {
              const isPriceChanged =
                oldPriceValue !== 0 && oldPriceValue !== priceValue;

              return (
                <>
                  {isPriceChanged ? (
                    <Typo
                      color="accent"
                      variant="xl"
                      className={styles.priceNew}
                      weight="500"
                    >
                      {MoneyFormatter.format(priceValue, {
                        currency: Currency.RUB,
                        withZeroFraction: true,
                      })}
                      <Typo
                        weight="500"
                        color="gray400"
                        variant="xl"
                        className={styles.priceOld}
                      >
                        {oldPriceValue
                          ? MoneyFormatter.format(oldPriceValue, {
                              currency: Currency.RUB,
                              withZeroFraction: true,
                            })
                          : undefined}
                      </Typo>
                    </Typo>
                  ) : (
                    <Typo weight="500" className={styles.priceNew} variant="xl">
                      {MoneyFormatter.format(priceValue, {
                        currency: Currency.RUB,
                        withZeroFraction: true,
                      })}
                    </Typo>
                  )}
                </>
              );
            }}
          </ProductPrice>

          <Spacer size={16} />
          <Row className={styles.cartItemInfoDetails}>
            {chunkArray(
              properties.filter(
                (property: TProperty) =>
                  !NOT_SHOWING_PROPERTY_TYPES.includes(property.code),
              ),
              2,
            ).map((chunkProperty: Array<TProperty>) => (
              <React.Fragment
                key={`${chunkProperty[0].name}/${chunkProperty[0].value}`}
              >
                {chunkProperty.map((property: TProperty) => (
                  <Column
                    span={{ s: 12, m: 6 }}
                    key={`${property.name}/${property.value}`}
                  >
                    <Typo variant="m">
                      {property.name}: {property.value}
                    </Typo>
                  </Column>
                ))}
              </React.Fragment>
            ))}
            <Column span={{ s: 12, m: 6 }}>
              <Typo variant="m" key={`Кол-во/${quantity}`}>
                Кол-во: {quantity}
              </Typo>
            </Column>
          </Row>
          <Spacer size={4} />
          {!isItemsEnough && (
            <Typo color="alert" variant="s">
              Осталось {quantityOffer} {remainWordPluralize}
            </Typo>
          )}
        </div>
      </div>
    </Link>
  );
}
