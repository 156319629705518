export enum SchemaOrgType {
  WebSite = 'WebSite',
  Product = 'Product',
  Offer = 'Offer',
  Organization = 'Organization',
  PostalAddress = 'PostalAddress',
  BreadcrumbList = 'BreadcrumbList',
  ListItem = 'ListItem',
  WPHeader = 'WPHeader',
  WPFooter = 'WPFooter',
}

type TMicrodataProps = {
  type?: SchemaOrgType;
  items?: Record<string, string | number>;
  prop?: string;
  element?: keyof JSX.IntrinsicElements;
  [prop: string]: unknown;
};

export const Microdata = ({
  type,
  items,
  prop,
  element,
  children,
  ...rest
}: React.PropsWithChildren<TMicrodataProps>): JSX.Element => {
  const Element = element ?? 'div';
  return (
    // Ругается на совместимость типов, а нам здесь нужно использовать разные элементы
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <Element
      hidden
      itemProp={prop}
      itemScope={!!type}
      itemType={type ? `http://schema.org/${type}` : undefined}
      {...rest}
    >
      {items &&
        Object.entries(items).map(([key, value]: [string, string | number]) => (
          <div itemProp={key} key={key + value}>
            {value}
          </div>
        ))}
      {children}
    </Element>
  );
};
