import { MediaBreakpoint } from '_shared';
import { Ribbon } from 'components/ribbon';
import { Image } from 'components/ui-kit';
import Link from 'next/link';
import { type JSX } from 'react';
import styles from './polka-lairs.module.scss';

export const PolkaLairsRibbon = (): JSX.Element => {
  return (
    <Ribbon
      id="004e02df-d6c6-4380-96bc-6de33de0edb7"
      className={styles.ribbon}
      background={{
        s: '/images/ribbon/polka-lairs/ribbon-bg_mob.jpg',
      }}
    >
      <Link
        href="/collaboration-polka-liars/?&utm_source=own_site&utm_medium=content&utm_campaign=polka&utm_content=ribbon"
        className="clear-link"
      >
        <div className={styles.content}>
          <Image
            className={styles.trinket}
            alt="Брелок"
            src="/images/ribbon/polka-lairs/trinket_mob.png"
            srcSet={[
              {
                media: MediaBreakpoint.MEDIUM_SCREEN,
                src: '/images/ribbon/polka-lairs/trinket_tablet.png',
                format: 'png',
              },
              {
                media: MediaBreakpoint.LARGE_SCREEN,
                src: '/images/ribbon/polka-lairs/trinket.png',
                format: 'png',
              },
            ]}
          />
          <Image
            className={styles.longsliv}
            alt="Лонгслив"
            src="/images/ribbon/polka-lairs/long_tablet.png"
            srcSet={[
              {
                media: MediaBreakpoint.MEDIUM_SCREEN,
                src: '/images/ribbon/polka-lairs/long.png',
                format: 'png',
              },
            ]}
          />
          <Image
            className={styles.poster}
            alt="Постер"
            src="/images/ribbon/polka-lairs/poster.png"
          />
          <Image
            className={styles.jaket}
            alt="Жакет"
            src="/images/ribbon/polka-lairs/jaket_mob.png"
            srcSet={[
              {
                media: MediaBreakpoint.MEDIUM_SCREEN,
                src: '/images/ribbon/polka-lairs/jaket_tablet.png',
                format: 'png',
              },
              {
                media: MediaBreakpoint.LARGE_SCREEN,
                src: '/images/ribbon/polka-lairs/jaket.png',
                format: 'png',
              },
            ]}
          />
          <Image
            className={styles.laierMobLogo}
            alt="Логотип Lairs Collective"
            src="/images/ribbon/polka-lairs/lairs-logo.svg"
          />
          <div className={styles.contentInner}>
            <div className={styles.titleWrapper}>
              <Image
                className={styles.logo}
                alt="Логотип Универмаг X Polka Lairs"
                src="/images/ribbon/polka-lairs/logo_mob.svg"
                srcSet={[
                  {
                    media: MediaBreakpoint.LARGE_SCREEN,
                    src: '/images/ribbon/polka-lairs/logo.svg',
                    format: 'png',
                  },
                  {
                    media: MediaBreakpoint.MEDIUM_SCREEN,
                    src: '/images/ribbon/polka-lairs/logo_tablet.svg',
                    format: 'png',
                  },
                ]}
              />
            </div>
            <div>
              <Image
                className={styles.line}
                alt="Весенняя коллекции «Перед набросками»"
                src="/images/ribbon/polka-lairs/line_tablet.svg"
                srcSet={[
                  {
                    media: MediaBreakpoint.LARGE_SCREEN,
                    src: '/images/ribbon/polka-lairs/line.svg',
                    format: 'png',
                  },
                ]}
              />
              <br />
              <span className={styles.link}>
                <span>Подробнее</span>
                <img
                  src="/images/ribbon/polka-lairs/arrow.svg"
                  alt="Подробнее"
                />
              </span>
            </div>

            <span className={styles.button}>
              <span>Перейти</span>
            </span>
          </div>
        </div>
      </Link>
    </Ribbon>
  );
};
