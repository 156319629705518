import CommonConsumptionGoods from '@tochka-modules/t15-ui-kit-icons-ssr/24/Stroked/CommonConsumptionGoods';
import { Drawer } from 'components/ui-kit';
import { useHistoryChange } from 'hooks/use-history-change';
import { HeaderCart, useCartContext } from 'modules/cart';
import { useState } from 'react';
import styles from '../../header.module.scss';

export const CartMenu = (): JSX.Element => {
  const [isCartVisible, setIsCartVisible] = useState(false);
  const { cart } = useCartContext();

  const cartPositionsCount =
    cart && cart?.positions.length >= 0 ? cart?.positions.length : '?';

  const toggleCart = (): void => {
    setIsCartVisible((prev: boolean) => !prev);
  };

  useHistoryChange(() => setIsCartVisible(false));

  return (
    <>
      <Drawer right overlayHeader isOpen={isCartVisible} onClose={toggleCart}>
        <HeaderCart onClose={toggleCart} />
      </Drawer>
      <button className={styles.button} type="button" onClick={toggleCart}>
        <CommonConsumptionGoods className={styles.buttonIcon} />
        <span className={styles.buttonBadge}>{cartPositionsCount}</span>
      </button>
    </>
  );
};
