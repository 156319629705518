'use client';
import SearchIcon from '@tochka-modules/t15-ui-kit-icons-ssr/24/Stroked/Search';
import { useHistoryChange } from 'hooks/use-history-change';
import { useState } from 'react';
import SearchModal from './components/search-modal/search-modal.component';
import styles from './search.module.scss';

const Search = (): JSX.Element => {
  const [isSearchModalOpen, setIsSearchModalOpen] = useState(false);

  const toggleSearchVisibility = (): void => {
    setIsSearchModalOpen((prevState: boolean) => !prevState);
  };

  // Закрываем модалку когда поменялся window.location
  useHistoryChange(() => {
    setIsSearchModalOpen(false);
  });

  return (
    <>
      <button className={styles.searchButton} onClick={toggleSearchVisibility}>
        <SearchIcon className={styles.searchButtonIcon} />
      </button>
      <SearchModal
        isOpen={isSearchModalOpen}
        onClose={toggleSearchVisibility}
      />
    </>
  );
};

export default Search;
