import { wrapStringInBoldHtml } from 'modules/search/helpers';
import Link from 'next/link';
import styles from '../../search.module.scss';

export type TSearchItem = {
  slug: string;
  name: string;
  link?: string;
};

type TSearchResultListProps = {
  /**
   * Список продуктов
   */
  list: Array<TSearchItem>;

  /**
   * Значение поисковой строки
   */
  searchQuery: string;
};

export const SearchResultList = ({
  list,
  searchQuery,
}: TSearchResultListProps): JSX.Element => {
  return (
    <div>
      {list.map((item: TSearchItem) => (
        <dd key={item.slug} className={styles.listItem}>
          <Link href={!item.link ? `/product/${item.slug}` : item.link}>
            {/* В ссылке поисковые вхождения должны выделяться жирным стилем (тегом b) */}
            <span
              className={styles.link}
              dangerouslySetInnerHTML={{
                __html: wrapStringInBoldHtml(item.name, searchQuery),
              }}
            />
          </Link>
        </dd>
      ))}
    </div>
  );
};
