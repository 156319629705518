import { Cross } from '@tochka-modules/t15-ui-kit-icons-ssr/24/Stroked/Cross';
import { Typo } from 'components/ui-kit';
import { carrotService } from 'lib';
import { useCartContext } from 'modules/cart/providers';
import { useEffect } from 'react';
import styles from './cart.module.scss';
import { HeaderCartBody, HeaderCartEmptyBody } from './components';

export type TCartProps = {
  onClose: VoidFunction;
};

export const headerCartTestSelectors = {
  headerCartClose: 'header-cart-close',
};
export function HeaderCart({ onClose }: TCartProps): JSX.Element | null {
  const { cart } = useCartContext();

  const isNotEmpty = cart && cart.positions.length > 0;

  useEffect(() => {
    carrotService.cartViewed(cart);
  }, [cart]);

  return (
    <>
      <div className={styles.cartHeader}>
        <Typo weight="500" className={styles.cartHeaderText} variant="xl">
          моя корзина {isNotEmpty && <>({cart?.positions.length})</>}
        </Typo>
        <button
          data-at={headerCartTestSelectors.headerCartClose}
          className={styles.cartHeaderButton}
          onClick={onClose}
        >
          <Cross />
        </button>
      </div>
      <div className={styles.scrollable}>
        {isNotEmpty ? <HeaderCartBody cart={cart} /> : <HeaderCartEmptyBody />}
      </div>
    </>
  );
}
