import { useRibbonContext } from 'components/ribbon';
import { SimpleImage } from 'components/simple-image';
import { Typo } from 'components/ui-kit';
import { useMedia } from 'hooks';
import {
  concatSlugs,
  concatSlugsOrAbsolutePaths,
} from 'lib/utils/concat-slugs';
import { NavigationItemModel } from 'modules/navigation';
import Link from 'next/link';
import { useEffect, useRef } from 'react';
import { TMenuItemProps } from './menu-item.component';
import styles from './menu-item.module.scss';
import { Breakpoint } from '../../../../../_shared';

type TMenuSubItemProps = TMenuItemProps & {
  parentPreviewImage: string | null;
  onBack: VoidFunction;
  navigationContainer: HTMLElement | null;
};

type TPreviewImageProps = { src: string; name: string };
const PreviewImage = ({ src, name }: TPreviewImageProps): JSX.Element => (
  <div className={styles.preview}>
    <SimpleImage
      alt={name}
      src={src}
      objectFit="cover"
      layout="responsive"
      quality={90}
      width={600}
      height={800}
    />
  </div>
);

export const MenuSubItem = ({
  menu,
  closeMenu,
  navigationContainer,
  onBack,
  parentPreviewImage,
}: TMenuSubItemProps): JSX.Element => {
  const { isVisibleRibbon, ribbonHeight } = useRibbonContext();
  const breakpoint = useMedia();
  const isMobile = breakpoint < Breakpoint.MEDIUM_SCREEN;
  const initialScroll = useRef(navigationContainer?.scrollTop || 0);
  const handleBack = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ): void => {
    event.stopPropagation();
    navigationContainer?.scrollTo(0, initialScroll.current);
    onBack();
  };

  useEffect(() => {
    navigationContainer?.scrollTo(0, 0);
  }, [navigationContainer]);

  const containerStyle: Record<string, string> = {
    '--offset-top': `${isVisibleRibbon ? ribbonHeight : 0}px`,
  };

  return (
    <div className={styles.container} style={containerStyle}>
      {isMobile && (
        <button className={styles.subMenuBackButton} onClick={handleBack}>
          <Typo className={styles.uppercase} weight="600" variant="m">
            {menu.name}
          </Typo>
          <span className={styles.accordeonArrowBack} />
        </button>
      )}

      {menu.children.map(
        ({ name, slug, parentSlug, previewImage }: NavigationItemModel) => {
          const image = previewImage || parentPreviewImage;
          return (
            <Link
              onClick={closeMenu}
              className={styles.subLink}
              key={name}
              href={concatSlugsOrAbsolutePaths(
                menu.parentSlug,
                parentSlug,
                slug,
              )}
            >
              {name}
              {!!image && <PreviewImage name={name} src={image} />}
            </Link>
          );
        },
      )}

      {menu.parentSlug && (
        <Link
          onClick={closeMenu}
          className={styles.subLink}
          href={concatSlugs(menu.parentSlug, menu.slug)}
          prefetch={false}
        >
          Смотреть все
          {!!parentPreviewImage && (
            <PreviewImage name={menu.name} src={parentPreviewImage} />
          )}
        </Link>
      )}
    </div>
  );
};
