import { useDebounce } from '@brand/utils';
import Modal from 'components/ui-kit/modal/modal.component';
import { productService, TProductResponse } from 'lib';
import { useRouter } from 'next/navigation';
import { useUserInfoContext } from 'providers/auth-provider/user-info-provider';
import { FormEvent, useCallback, useEffect, useState } from 'react';
import { POPULAR_PRODUCTS } from '../../data/popular-products';
import styles from '../../search.module.scss';
import { SearchResultList } from '../seach-result-list/search-result-list.component';
import { SearchInput } from '../search-input/search-input.component';
import { TradeOfferList } from '../trade-offer-list/trade-offer-list.component';

type TSearchModalProps = {
  /**
   * Статус активности поиска
   */
  isOpen: boolean;
  /**
   * Функция, которая будет запущена, когда модальное окно будет запрошено быть закрытым.
   * Параметр isOpen должен быть обновлен.
   */
  onClose: () => void;
};

const SEARCH_DELAY = 500;

const SearchModal = ({ isOpen, onClose }: TSearchModalProps): JSX.Element => {
  const { profile } = useUserInfoContext();
  const [items, setItems] = useState<Array<TProductResponse>>([]);
  const [query, setQuery] = useState<string>('');
  const router = useRouter();
  const debouncedQuery = useDebounce(query, SEARCH_DELAY);
  const hasSearchRequestResults = !!debouncedQuery.length && !!items.length;
  const isSearchQueryEmpty = !debouncedQuery.length;

  useEffect(() => {
    if (isOpen) {
      void productService
        .searchProducts({
          fields: ['slug', 'id', 'name', 'is_available'],
          search: debouncedQuery,
          limit: 10,
          isAvailable: true,
          onlyEmployees: profile?.isEmployee ? undefined : false,
        })
        .then(setItems);
    }
  }, [isOpen, debouncedQuery, profile]);

  const onInputChange = useCallback((value: string) => {
    setQuery(value);
  }, []);

  const handleSubmit = (event: FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    void router.push(`/search?query=${debouncedQuery}`);
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose}>
      <dl className={styles.list}>
        <form onSubmit={handleSubmit}>
          <SearchInput autoFocus value={query} onChange={onInputChange} />
        </form>
        {hasSearchRequestResults && (
          <SearchResultList list={items} searchQuery={debouncedQuery} />
        )}
        {isSearchQueryEmpty && (
          <TradeOfferList popularProducts={POPULAR_PRODUCTS} />
        )}
      </dl>
    </Modal>
  );
};

export default SearchModal;
