import { useScrollLock } from '@brand/utils';
import { SimpleImage } from 'components/simple-image';
import { useMedia } from 'hooks';
import { concatSlugsOrAbsolutePaths } from 'lib/utils/concat-slugs';
import { NavigationItemModel } from 'modules/navigation';
import { useCallback, useMemo, useState } from 'react';
import styles from './menu-item.module.scss';
import { MenuSubItem } from './menu-sub-item.component';
import { Breakpoint } from '../../../../../_shared';
import { MenuLink } from '../menu-link';

export type TMenuItemProps = {
  menu: NavigationItemModel;
  closeMenu?: VoidFunction;
  navigationContainer: HTMLElement | null;
};

export const MenuItem = ({
  menu,
  closeMenu,
  navigationContainer,
}: TMenuItemProps): JSX.Element => {
  const [isVisible, setIsVisible] = useState(false);
  const breakpoint = useMedia();
  const isMobile = breakpoint < Breakpoint.MEDIUM_SCREEN;
  const showMenu = useCallback(() => setIsVisible(true), []);
  const hideMenu = useCallback(() => setIsVisible(false), []);

  useScrollLock(isVisible, isMobile ? navigationContainer : null);

  const handlers = useMemo(() => {
    if (isMobile) {
      return { onClick: showMenu };
    }

    return {
      onTouchStart: showMenu,
      onMouseLeave: hideMenu,
      onMouseEnter: showMenu,
    };
  }, [isMobile, showMenu, hideMenu]);

  return (
    <span {...handlers}>
      <MenuLink
        className={styles.link}
        isAlert={menu.isAlert}
        tags={menu.tags}
        hasChildren={menu.children.length > 0}
        href={concatSlugsOrAbsolutePaths(menu.parentSlug, menu.slug)}
        title={menu.name}
        closeMenu={closeMenu}
      />
      {!!menu.previewImage && (
        <div className={styles.previewParent}>
          <SimpleImage
            alt={menu.name}
            src={menu.previewImage ?? ''}
            objectFit="cover"
            layout="responsive"
            quality={90}
            width={1920}
            height={1080}
          />
        </div>
      )}

      {menu.children.length > 0 && isVisible && (
        <MenuSubItem
          parentPreviewImage={menu.previewImage}
          navigationContainer={navigationContainer}
          menu={menu}
          closeMenu={closeMenu}
          onBack={hideMenu}
        />
      )}
    </span>
  );
};
