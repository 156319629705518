import ArrowRight from '@tochka-modules/t15-ui-kit-icons-ssr/24/Stroked/ArrowRight';
import SearchIcon from '@tochka-modules/t15-ui-kit-icons-ssr/24/Stroked/Search';
import { ChangeEvent } from 'react';
import styles from './search-input.module.scss';

type TSearchInputProps = {
  /**
   * Текущее значение поиска
   */
  value: string;

  /**
   * Функция, которая сработает при изменении значения
   */
  onChange: (value: string) => void;

  /**
   * Текст-подсказка для ввода данных
   */
  placeholder?: string;
  /**
   * Флаг: автофокус при рендере
   */
  autoFocus?: boolean;
};

export const SearchInput = ({
  value,
  onChange,
  placeholder = 'Введите слово для поиска',
  autoFocus,
}: TSearchInputProps): JSX.Element => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.iconWrapper}>
        <SearchIcon />
      </div>
      <input
        maxLength={255}
        autoFocus={autoFocus}
        value={value}
        onChange={(event: ChangeEvent<HTMLInputElement>) =>
          onChange(event.target.value)
        }
        className={styles.input}
        placeholder={placeholder}
      />
      {value.length > 0 && (
        <button type="submit" className={styles.inputButton}>
          <ArrowRight />
        </button>
      )}
    </div>
  );
};
