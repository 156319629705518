import { Amount, Button, Typo } from 'components/ui-kit';
import { CartPositions } from 'modules/cart/api';
import { GetCartResponseModel } from 'modules/cart/api/models/get-cart-response.model';
import { EmployeeNotice } from 'modules/cart/components';
import { DevliveryNotice } from 'modules/cart/components/common/delivery-notice';
import { useUserInfoContext } from 'providers/auth-provider/user-info-provider';
import styles from './../../cart.module.scss';
import { CartPositionItem } from '../cart-position-item/cart-position-item.component';

type THeaderCartBody = {
  cart: GetCartResponseModel | undefined;
};

export const HeaderCartBody = ({
  cart,
}: THeaderCartBody): JSX.Element | null => {
  const { profile } = useUserInfoContext();

  if (!cart) return null;

  const preorderProducts = cart.positions.filter(
    ({ product }: CartPositions) => product.isPreOrder,
  );

  const hasPreorderProduct =
    preorderProducts.length > 0 &&
    preorderProducts.length !== cart.positions.length;

  return (
    <>
      {cart.positions.map((position: CartPositions) => (
        <CartPositionItem key={position.id} position={position} />
      ))}
      <div className={styles.paddingCart}>
        <div className={styles.cartTotal}>
          <Typo variant="2xl" weight="600">
            Итого
          </Typo>
          <Amount
            withZeroFraction
            value={cart?.positionTotalPrice?.priceValue || 0}
          />
        </div>
        <Button
          className={styles.button}
          type="inner-link"
          href="/cart"
          variant="primary"
          size="xl"
          isBlock
        >
          Перейти в&nbsp;корзину
        </Button>
        <div className={styles.content}>
          {hasPreorderProduct ? (
            <Typo variant="xm" color="alert" className={styles.employeeNotice}>
              В&nbsp;корзине есть товар по&nbsp;предзаказу. Оформите его
              отдельно
            </Typo>
          ) : (
            <EmployeeNotice
              profile={profile}
              className={styles.employeeNotice}
            />
          )}
          <DevliveryNotice />
        </div>
      </div>
    </>
  );
};
