import cn from 'classnames';
import { Typo } from 'components/ui-kit';
import { useMedia } from 'hooks';
import Link from 'next/link';
import { createElement, useMemo } from 'react';
import styles from './menu-link.module.scss';
import { Breakpoint } from '../../../../../_shared';
import { MenuTag } from '../menu-tag';

type TMenuLinkProps = {
  href?: string | null;
  title: string;
  tags?: Array<string>;
  isAlert?: boolean;
  closeMenu?: VoidFunction;
  hasChildren?: boolean;
  className?: string;
};

export const MenuLink = ({
  href,
  title,
  isAlert,
  tags,
  closeMenu,
  hasChildren,
  className,
}: TMenuLinkProps): JSX.Element => {
  const breakpoint = useMedia();
  const isMobile = breakpoint < Breakpoint.MEDIUM_SCREEN;

  const Node = useMemo(() => {
    if (isMobile) {
      return href && !hasChildren ? Link : 'span';
    }
    return href ? Link : 'span';
  }, [href, isMobile, hasChildren]);

  return createElement(
    Node,
    {
      href: href as string,
      className: cn(className, styles.navItem),
      onClick: Node !== 'span' ? closeMenu : undefined,
    },
    <>
      <Typo color="primary" variant="m" weight="600">
        <span
          className={cn(styles.uppercase, {
            [styles.navLinkAlert]: isAlert,
          })}
        >
          {title}
          {tags?.map((item: string) => (
            <MenuTag key={item} title={item} />
          ))}
        </span>
      </Typo>
      {hasChildren && <span className={styles.accordeonArrow} />}
    </>,
  );
};
