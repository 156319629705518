import { OfferModel, OfferPrice } from 'lib';
import { useUserInfoContext } from 'providers/auth-provider/user-info-provider';
import { useCallback, useEffect, useState } from 'react';
import { CartOfferModel } from '../modules/cart';

export const useProductPrice = (
  offer?: OfferModel | CartOfferModel | null,
): {
  price?: OfferPrice;
  isLoading: boolean;
  getPrice: (
    offer?: OfferModel | CartOfferModel | null,
  ) => OfferPrice | undefined;
} => {
  const { pricePolicy, isLoading } = useUserInfoContext();
  const [price, setPrice] = useState(() => offer?.prices[pricePolicy]);
  useEffect(() => {
    setPrice(offer?.prices[pricePolicy]);
  }, [pricePolicy, offer?.prices]);

  const getPrice = useCallback(
    (offerModel?: OfferModel | CartOfferModel | null) =>
      offerModel?.prices[pricePolicy],
    [pricePolicy],
  );

  return { price, isLoading, getPrice };
};
