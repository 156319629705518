import { Typo } from 'components/ui-kit';
import { Profile } from 'lib';
import React, { ReactNode } from 'react';

type TEmployeeNoticeProps = {
  profile?: Profile | null;
  className?: string;
};

const getNoticeText = (profile: Profile): ReactNode => {
  if (profile.hasPersonalDiscount) {
    return (
      <>
        Цена ниже обычной только для своих.
        <br />
        Пусть обновки принесут вдохновение!
      </>
    );
  }

  if (profile.isEmployee) {
    return (
      <>Часть команды&nbsp;&mdash; часть корабля! Цена для тебя ниже обычной</>
    );
  }

  return null;
};

export const EmployeeNotice = ({
  profile,
  className,
}: TEmployeeNoticeProps): JSX.Element | null => {
  if (!profile?.isEmployee && !profile?.hasPersonalDiscount) return null;

  return (
    <Typo variant="xm" color="green" className={className}>
      {getNoticeText(profile)}
    </Typo>
  );
};
