import cn from 'classnames';
import { Drawer } from 'components/ui-kit';
import { Profile } from 'lib';
import { NavigationItemModel, NavigationResponse } from 'modules/navigation';
import { TNavigationItemAvailability } from 'modules/navigation/api/models/navigation.model';
import { useUserInfoContext } from 'providers/auth-provider/user-info-provider';
import { useCallback, useRef, useState } from 'react';
import { MenuItem } from './components/menu-item';
import styles from './menu.module.scss';

type TMenuProps = {
  isOpen?: boolean;
  navigation: NavigationResponse;
};

const getMenuItemAvailability = (
  profile?: Profile | null,
): Array<TNavigationItemAvailability | false | undefined> => [
  'all',
  !!profile?.isEmployee && 'employees',
  !!profile && 'auth',
];

export const Menu = ({
  isOpen,
  navigation: navigationData,
}: TMenuProps): JSX.Element => {
  const [isMenuVisible, setIsMenuVisible] = useState<boolean | undefined>(
    isOpen,
  );
  const navigationContainerRef = useRef<HTMLElement | null>(null);
  const toggleMenu = (): void => {
    setIsMenuVisible((prev?: boolean): boolean => !prev);
  };

  const closeMenu = useCallback(() => setIsMenuVisible(false), []);
  const { profile } = useUserInfoContext();
  const navigation = navigationData.data?.filter((item: NavigationItemModel) =>
    getMenuItemAvailability(profile).includes(item.availability),
  );

  return (
    <>
      <button
        className={cn(styles.burger, {
          [styles.activeBurger]: isMenuVisible,
        })}
        type="button"
        onClick={toggleMenu}
      >
        <span
          className={cn(styles.burgerIcon, {
            [styles.activeBurgerIcon]: isMenuVisible,
          })}
        >
          <span />
        </span>
        <span className={styles.burgerLabel}>меню</span>
      </button>

      <Drawer isOpen={isMenuVisible} onClose={closeMenu}>
        <nav className={styles.navigation} ref={navigationContainerRef}>
          {navigation?.map((menu: NavigationItemModel) => (
            <MenuItem
              navigationContainer={navigationContainerRef.current}
              key={menu.name}
              menu={menu}
              closeMenu={closeMenu}
            />
          ))}
        </nav>
      </Drawer>
    </>
  );
};
