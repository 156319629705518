'use client';
import { Person } from '@tochka-modules/t15-ui-kit-icons-ssr/24/Stroked/Person';
import { MediaBreakpoint } from '_shared';
import cn from 'classnames';

import { Microdata } from 'components/microdata';
import { SchemaOrgType } from 'components/microdata/microdata.component';
import { Image } from 'components/ui-kit';
import { EAuthScreen } from 'modules/auth/auth-modal.const';
import { useAuthRoutes } from 'modules/auth/use-auth-route';
import { NavigationResponse } from 'modules/navigation';
import Search from 'modules/search/search.component';
import Link from 'next/link';
import { useRouter } from 'next/navigation';
import { useUserInfoContext } from 'providers/auth-provider/user-info-provider';
import { CartMenu } from './components';
import { PageRibbon } from './components/page-ribbon/page-ribbon.component';
import styles from './header.module.scss';
import { Menu } from '../menu/menu.component';

type THeaderProps = {
  navigation?: NavigationResponse;
};

const HEADER_MICRODATA = {
  headline: 'Универмаг',
  description:
    'В Универмаге вы найдёте одежду и предметы, смысл которых — вдохновлять предпринимать ✈ Бесплатная доставка по России при заказе от 5000₽ ✔ Банк Точка',
  keywords:
    'Точка, Точка банк, банк Точка, универмаг, магазин, бизнес, доставка, предприниматель',
};

export const Header = ({ navigation }: THeaderProps): JSX.Element => {
  const router = useRouter();
  const { profile } = useUserInfoContext();
  const { show } = useAuthRoutes();

  const handleLogin = (): void => {
    profile ? void router.push('/account') : show(EAuthScreen.LOGIN);
  };

  return (
    <header className={cn(styles.root)}>
      <PageRibbon />
      <div className={styles.rootContainer}>
        <div className={styles.leftColumn}>
          {!!navigation && <Menu navigation={navigation} />}
        </div>
        <div className={styles.middleColumn}>
          <Link className={styles.logo} href="/">
            <div className={styles.logoImage}>
              <Image
                className={styles.logo}
                alt="Логотип Универмаг"
                src="/images/univermag-logo_mob.svg"
                srcSet={[
                  {
                    media: MediaBreakpoint.MEDIUM_SCREEN,
                    src: '/images/univermag-logo.svg',
                    format: 'png',
                  },
                ]}
              />
            </div>
          </Link>
        </div>
        <div className={styles.rightColumn}>
          <div className={styles.buttonGroup}>
            <Search />
            <span className={styles.button} onClick={handleLogin}>
              <Person className={styles.buttonIcon} />
            </span>
            <CartMenu />
          </div>
        </div>
      </div>
      <Microdata type={SchemaOrgType.WPHeader} items={HEADER_MICRODATA} />
    </header>
  );
};
