'use client';
import { useProductPrice } from 'hooks/use-product-price';
import { OfferModel, OfferPrice } from 'lib';
import { CartOfferModel } from '../../modules/cart';

export type TProductPriceProps = {
  offer?: OfferModel | CartOfferModel | null;
  children: (price: OfferPrice) => JSX.Element;
  className?: string;
};
export const ProductPrice = ({
  offer,
  children,
  className,
}: TProductPriceProps): JSX.Element => {
  const { price } = useProductPrice(offer);
  return <div className={className}>{price ? children(price) : null}</div>;
};
