import { Typo } from 'components/ui-kit';

export const DevliveryNotice = (): JSX.Element => {
  return (
    <Typo variant="xm">
      Стоимость доставки рассчитывается
      <br />
      на этапе оформления заказа
    </Typo>
  );
};
